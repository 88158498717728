<template>
  <v-overlay
    :value="show"
  >
    <v-card
      width="500"
      light
    >
      <v-toolbar
        color="#333"
        dark
      >
        <div class="text-h5">
          Пересчет стоимости
        </div>
      </v-toolbar>
      <v-card-text class="pa-6">
        Вы уверены, что хотите пересчитать стоимость товаров в BYN?
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#333"
          text
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5CB7B1"
          dark
          @click="submit"
        >
          Пересчитать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
import {mapGetters} from "vuex";

export default {
  mixins: [closeOnEscape],
  props: {
    show: {
      required: true,
      type: Boolean
    },
  },
  computed: {
    ...mapGetters({
      selected: 'statistics/getSelected'
    })
  },
  methods: {
    submit() {
      this.$store.dispatch('statistics/recalculateCostInBYN', this.selected.id).then(() => {
        this.$snackbar({text: "Готово", color: "green", top: false, right: false});
        this.close()
      }).catch(() => {
        this.$snackbar({text: "Ошибка", color: "red", top: false, right: false});
      })
    },
    close() {
      this.$emit('update:show', false)
    }
  }
}
</script>
